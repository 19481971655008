* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
  background-color: #3b3b3b;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

#helpButtom {
  position: absolute;
  height: 1.8rem;
  left: 50%;
  transform: translate(-50%);
  bottom: 1rem;
  opacity: 0;
}

#muteButtom {
  position: absolute;
  height: 1.5rem;
  left: 50%;
  transform: translate(-50%);
  top: 1rem;
  left: 96%;
  opacity: 0;
}

#loadingImage {
  position: absolute;
  height: 60%;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

#loadingProgress {
  position: absolute;
  color: #b1dfff;
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: center;
  font-family: "Concert One", Arial, Verdana, Helvetica, cursive;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}
